import poster_1 from "./poster (1).png";
import poster_3 from "./poster (3).png";
import poster_2 from "./poster (2).png";
import poster_4 from "./poster (4).png";
import poster_5 from "./poster (5).png";
import poster_6 from "./poster (6).png";
import poster_7 from "./poster (7).png";
import poster_8 from "./poster (8).png";
import poster_9 from "./poster (9).png";
import poster_10 from "./poster (10).png";
import poster_11 from "./poster (11).png";
import poster_12 from "./poster (12).png";
import poster_13 from "./poster (13).png";
import poster_14 from "./poster (14).png";
import poster_15 from "./poster (15).png";
import poster_16 from "./poster (16).png";
import poster_17 from "./poster (17).png";
import poster_18 from "./poster (18).png";
import poster_19 from "./poster (19).png";
import poster_20 from "./poster (20).png";
import poster_21 from "./poster (21).png";
import poster_22 from "./poster (22).png";
import poster_23 from "./poster (23).png";
import poster_24 from "./poster (24).png";
import poster_25 from "./poster (25).png";
import poster_26 from "./poster (26).png";

export const imgArr1 = [
    { image1:  poster_1, text: "Fruistick" },
    { image1:  poster_2, text: "Fruistick" },
    { image1:  poster_3, text: "Fruistick" },
    { image1:  poster_4, text: "Fruistick" },
    { image1:  poster_5, text: "Fruistick" },
    { image1:  poster_6, text: "Fruistick" },
    { image1:  poster_7, text: "Fruistick" },
    { image1:  poster_8, text: "Fruistick" },
    { image1:  poster_9, text: "Fruistick" },
    { image1:  poster_10, text: "Fruistick" },
    { image1:  poster_11, text: "Fruistick" },
    { image1:  poster_12, text: "Fruistick" },
    { image1:  poster_13, text: "Fruistick" },
];
export const imgArr2 = [
    { image1:  poster_14, text: "Fruistick" },
    { image1:  poster_15, text: "Fruistick" },
    { image1:  poster_16, text: "Fruistick" },
    { image1:  poster_17, text: "Fruistick" },
    { image1:  poster_18, text: "Fruistick" },
    { image1:  poster_19, text: "Fruistick" },
    { image1:  poster_20, text: "Fruistick" },
    { image1:  poster_21, text: "Fruistick" },
    { image1:  poster_22, text: "Fruistick" },
    { image1:  poster_23, text: "Fruistick" },
    { image1:  poster_24, text: "Fruistick" },
    { image1:  poster_25, text: "Fruistick" },
    { image1:  poster_26, text: "Fruistick" },
];
